import request from "@/utils/request";
/*
0、公用
*/
// 根据护照号获取护照信息 1、是护照号 0是员工编码
export function getUsersCode(params) {
  return request({
    url: `/api/v1/users/${params.code}`,
    method: "get",
    params,
  });
}

//银行信息
export function getBankAccountBasic(params) {
  return request({
    url: `/api/v1/bankAccount/basic`,
    method: "get",
    params,
  });
}

//基本信息
export function getGeneralBasic(params) {
  return request({
    url: `/api/v1/general/basic`,
    method: "get",
    params,
  });
}

//飞行路线
export function getAirlinesAll(params) {
  return request({
    url: `/api/v1/airline/all`,
    method: "get",
    params,
  });
}

//获取全部公司或政府或出票机构或医保公司
export function getGovernmentSystemDicAll(params) {
  return request({
    url: `/api/v1/governmentSystemDic/all`,
    method: "get",
    params,
  });
}

/*
1、机票撤离相关-撤离
*/

//1-1 获取机票撤离列表
export function getDemobilizationApplications(params) {
  return request({
    url: "/api/v1/demobilizationApplications",
    method: "get",
    params,
  });
}

//1-1-1 列表员工
export function getDemobilizationApplications2(params) {
  return request({
    url: "/api/v1/employee/demobilizationApplications",
    method: "get",
    params,
  });
}

//1-2 机票撤离添加表格
export function addDemobilizationApplications(data) {
  return request({
    url: "/api/v1/demobilizationApplication/add",
    method: "post",
    data,
  });
}

export function addDemobilizationApplications2(data) {
  return request({
    url: "/api/v1/employee/demobilizationApplication/add",
    method: "post",
    data,
  });
}

//1-3 获取全部飞行路线
export function getTicketGroupAll(params) {
  return request({
    url: "/api/v1/ticketGroups/all",
    method: "get",
    params,
  });
}

// 1-4 获取详情
export function getDemobilizationApplicationsItem(params) {
  return request({
    url: `/api/v1/demobilizationApplications/${params.key}`,
    method: "get",
    params,
  });
}

export function getDemobilizationApplicationsItem2(params) {
  return request({
    url: `/api/v1/employee/demobilizationApplication/${params.key}`,
    method: "get",
    params,
  });
}

//1-5 修改撤离
export function updateDemobilizationApplication(data) {
  return request({
    url: "/api/v1/demobilizationApplication/edit",
    method: "post",
    data,
  });
}
export function updateDemobilizationApplication2(data) {
  return request({
    url: "/api/v1/employee/demobilizationApplication/edit",
    method: "post",
    data,
  });
}

//2-6 删除
export function deleteDemobilizationApplication(params) {
  return request({
    url: `/api/v1/demobilizationApplication/delete`,
    method: "delete",
    params,
  });
}
export function deleteDemobilizationApplication2(params) {
  return request({
    url: `/api/v1/employee/demobilizationApplication/delete`,
    method: "delete",
    params,
  });
}

//2-7 合并
export function demobilizationApplicationTicketBooking(data) {
  return request({
    url: `/api/v1/demobilizationApplication/ticketBooking`,
    method: "put",
    data,
  });
}

//2-8 修改外事关系
export function putDemobilizationApplicationItem(data) {
  return request({
    url: `/api/v1/demobilizationApplication/put`,
    method: "post",
    data,
  });
}

/*
2、机构资质
*/

// 2-1 机构资质列表
export function getOrgQualifications(params) {
  return request({
    url: "/api/v1/orgQualifications",
    method: "get",
    params,
  });
}

//2-2 下载机构资质导入模板
export function importOrgQualifications(params) {
  return request({
    url: "/api/v1/orgQualification/import/template",
    method: "get",
    params,
  });
}

//2-3 导入模板
export function importOrgQualificationsTemplate(data) {
  return request({
    url: "/api/v1/orgQualification/import",
    method: "post",
    data,
  });
}

// 2-4 导出数据
export function exportOrgQualifications(params) {
  return request({
    url: "/api/v1/orgQualification/export",
    method: "get",
    params,
  });
}

//2-5 新建表格
export function addOrgQualification(data) {
  return request({
    url: "/api/v1/orgQualification/add",
    method: "post",
    data,
  });
}

//2-6 修改
export function updateOrgQualification(data) {
  return request({
    url: "/api/v1/orgQualification/update",
    method: "post",
    data,
  });
}

//2-7 详情
export function getOrgQualificationsItem(params) {
  return request({
    url: `/api/v1/orgQualifications/${params.id}`,
    method: "get",
    params,
  });
}

//2-8 删除
export function deleteOrgQualificationItem(data) {
  return request({
    url: `/api/v1/orgQualification/delete`,
    method: "delete",
    data,
  });
}

/*
3、政府授权
*/

// 3-1 政府授权列表
export function getGovSystems(params) {
  return request({
    url: "/api/v1/governmentSystemDics",
    method: "get",
    params,
  });
}

//3-2 下载政府授权导入模板
export function importGovSystem(params) {
  return request({
    url: "/api/v1/govSystem/import/template",
    method: "get",
    params,
  });
}

//3-3 政府授权导入模板
export function importGovSystemTemplate(data) {
  return request({
    url: "/api/v1/govSystem/import",
    method: "post",
    data,
  });
}

//3-4 导出
export function exportGovSystem(params) {
  return request({
    url: "/api/v1/govSystem/export",
    method: "get",
    params,
  });
}

//3-5 新增
export function addGovSystem(data) {
  return request({
    url: "/api/v1/governmentSystemDic/add",
    method: "post",
    data,
  });
}

//3-6 修改
export function updateGovSystem(data) {
  return request({
    url: "/api/v1/governmentSystemDic/update",
    method: "post",
    data,
  });
}

//3-7 详情
export function getGovSystemItem(params) {
  return request({
    url: `/api/v1/governmentSystemDics/${params.id}`,
    method: "get",
    params,
  });
}

//3-8 删除
export function deleteGovSystemItem(data) {
  return request({
    url: `/api/v1/govSystem/delete`,
    method: "delete",
    data,
  });
}

/*
4、签证审批
 */

// 4-1签证审批列表
export function getEmployeeVisaApplications(params) {
  return request({
    url: "/api/v1/employeeVisaApplications",
    method: "get",
    params,
  });
}

//4-2 新增签证审批表
export function addEmployeeVisaApplication(data) {
  return request({
    url: "/api/v1/employeeVisaApplication/add",
    method: "post",
    data,
  });
}

//4-3 签证审批表详情
export function getEmployeeVisaApplicationsItem(params) {
  return request({
    url: `/api/v1/employeeVisaApplications/${params.key}`,
    method: "get",
    params,
  });
}

//4-4 编辑签证审批表
export function editEmployeeVisaApplication(data) {
  return request({
    url: `/api/v1/employeeVisaApplication/edit`,
    method: "post",
    data,
  });
}

//4-5 编辑签证审批表
export function deleteEmployeeVisaApplication(params) {
  return request({
    url: `/api/v1/employeeVisaApplication/delete`,
    method: "delete",
    params,
  });
}

// 2-6 导出数据
export function exportEmployeeVisaApplication(params) {
  return request({
    url: "/api/v1/employeeVisaApplication/export",
    method: "get",
    params,
  });
}
/*
 5、员工邀请函 
*/
// 5-1 员工邀请函列表
export function getInvitationLetterApplications(params) {
  return request({
    url: "/api/v1/invitationLetterApplications",
    method: "get",
    params,
  });
}

// 5-2 新增员工邀请函
export function addInvitationLetterApplication(data) {
  return request({
    url: "/api/v1/invitationLetterApplication/add",
    method: "post",
    data,
  });
}

// 5-3 员工邀请函详情
export function getInvitationLetterApplicationItem(params) {
  return request({
    url: `/api/v1/invitationLetterApplications/${params.key}`,
    method: "get",
    params,
  });
}

//5-4 员工邀请函修改
export function updateInvitationLetterApplication(data) {
  return request({
    url: "/api/v1/invitationLetterApplication/edit",
    method: "post",
    data,
  });
}
// 5-5 删除
export function deleteInvitationLetterApplicationItem(params) {
  return request({
    url: `/api/v1/invitationLetterApplication/delete`,
    method: "delete",
    params,
  });
}

/*
6、签证审批台账
*/

// 6-1 获取签证审批台账列表
export function getEmployeeVisaHistories(params) {
  return request({
    url: "/api/v1/employeeVisaHistories",
    method: "get",
    params,
  });
}

// 6-2 导出签证审批台账列表
export function exportEmployeeVisaHistory(params) {
  return request({
    url: "/api/v1/employeeVisaHistory/export",
    method: "get",
    params,
  });
}

/* 
7、人员信息修改
*/

// 7-1 获取人员信息列表
export function getEmployeeInfoChangeApplications(params) {
  return request({
    url: "/api/v1/employeeInfoChangeApplications",
    method: "get",
    params,
  });
}
// 7-2 详情
export function getEmployeeInfoChangeApplicationsItem(params) {
  return request({
    url: `/api/v1/employeeInfoChangeApplications/${params.id}`,
    method: "get",
    params,
  });
}

// 7-3 审核
export function auditEmployeeInfoChangeApplication(data) {
  return request({
    url: `/api/v1/employeeInfoChangeApplication/audit`,
    method: "post",
    data,
  });
}

/*
8 员工携带家属
*/

// 8-1 获取列表
export function getInvitationLetterApplication2s(params) {
  return request({
    url: "/api/v1/familyVisitVisaNvitationLetterApplications",
    method: "get",
    params,
  });
}

// 8-2 新增员工携带家属邀请函
export function addInvitationLetterApplication2(data) {
  return request({
    url: "/api/v1/familyVisitVisaNvitationLetterApplication/add",
    method: "post",
    data,
  });
}

// 8-3 详情
export function getInvitationLetterApplicationItem2(params) {
  return request({
    url: `/api/v1/familyVisitVisaNvitationLetterApplications/${params.key}`,
    method: "get",
    params,
  });
}

//8-4 编辑
export function editInvitationLetterApplicationItem2(data) {
  return request({
    url: `/api/v1/familyVisitVisaNvitationLetterApplications/edit`,
    method: "post",
    data,
  });
}

//8-5 删除
export function deleteInvitationLetterApplicationItem2(params) {
  return request({
    url: `/api/v1/familyVisitVisaNvitationLetterApplications/delete`,
    method: "delete",
    params,
  });
}

/*
9、不良人员
*/

// 9-1 不良人员列表
export function getBlacklists(params) {
  return request({
    url: "/api/v1/blacklists",
    method: "get",
    params,
  });
}

// 9-2 新增不良人员
export function addBlackItem(data) {
  return request({
    url: "/api/v1/blacklist/add",
    method: "post",
    data,
  });
}

//9-3 编辑不良人员
export function updateBlackItem(data) {
  return request({
    url: "/api/v1/blacklist/update",
    method: "post",
    data,
  });
}

//9-4 编辑不良人员
export function getBlackItem(params) {
  return request({
    url: `api/v1/blacklists/${params.id}`,
    method: "get",
    params,
  });
}

//9-5 下载机构资质导入模板
export function importBlacklistTemplate(params) {
  return request({
    url: "/api/v1/blacklist/import/template",
    method: "get",
    params,
  });
}

//9-6 导入模板
export function importBlacklist(data) {
  return request({
    url: "/api/v1/blacklist/import",
    method: "post",
    data,
  });
}
//9-7 导出
export function exportBlacklist(params) {
  return request({
    url: "/api/v1/blacklist/export",
    method: "get",
    params,
  });
}
//9-8 删除
export function deleteBlacklistItem(data) {
  return request({
    url: "/api/v1/blacklist/delete",
    method: "delete",
    data,
  });
}

/*
10、机票请购
*/

// 10-1 列表
export function getFlightTicketApplications(params) {
  return request({
    url: "/api/v1/employeeFlightTicketApplications",
    method: "get",
    params,
  });
}

export function getFlightTicketApplications2(params) {
  return request({
    url: "/api/v1/employee/employeeFlightTicketApplications",
    method: "get",
    params,
  });
}

//10-2 添加审批表
export function addFlightTicketApplication(data) {
  return request({
    url: "/api/v1/employeeFlightTicketApplication/add",
    method: "post",
    data,
  });
}

export function addFlightTicketApplication2(data) {
  return request({
    url: "/api/v1/employee/employeeFlightTicketApplication/add",
    method: "post",
    data,
  });
}

// 10-3 获取审批表详情
export function getFlightTicketApplicationsItem(params) {
  return request({
    url: `/api/v1/employeeFlightTicketApplications/${params.key}`,
    method: "get",
    params,
  });
}

export function getFlightTicketApplicationsItem2(params) {
  return request({
    url: `/api/v1/employee/employeeFlightTicketApplications/${params.key}`,
    method: "get",
    params,
  });
}

// 10-4 修改审批表
export function editFlightTicketApplication(data) {
  return request({
    url: "/api/v1/employeeFlightTicketApplication/edit",
    method: "post",
    data,
  });
}

export function editFlightTicketApplication2(data) {
  return request({
    url: "/api/v1/employee/employeeFlightTicketApplication/edit",
    method: "post",
    data,
  });
}
// 10-5 删除审批表
export function deleteFlightTicketApplicationItem(params) {
  return request({
    url: "/api/v1/employeeFlightTicketApplication/delete",
    method: "delete",
    params,
  });
}
export function deleteFlightTicketApplicationItem2(params) {
  return request({
    url: "/api/v1/employee/employeeFlightTicketApplication/delete",
    method: "delete",
    params,
  });
}

export function getLeaveDayApplicationNumber(params) {
  return request({
    url: `/api/v1/employeeFlightTicketApplication/leaveDay/${params.applicationNumber}`,
    method: "get",
    params,
  });
}
// 10-6 修改外事关系
export function putEmployeeFlightTicketApplicationItem(data) {
  return request({
    url: `/api/v1/employeeFlightTicketApplication/put`,
    method: "post",
    data,
  });
}
/*
11、机票变更
*/

// 11-1 列表
export function getFlightTicketReccheduleApplication(params) {
  return request({
    url: "/api/v1/flightTicketRescheduleApplication",
    method: "get",
    params,
  });
}

export function getFlightTicketReccheduleApplication2(params) {
  return request({
    url: "/api/v1/employee/flightTicketRescheduleApplications",
    method: "get",
    params,
  });
}

//11-2 获取本人最近一次机票
export function getLastestTicketBooking(params) {
  return request({
    url: `/api/v1/flightTicketRescheduleApplication/lastestTicketBooking/${params.ppCode}`,
    method: "get",
    params,
  });
}

//11-3 添加表单
export function addFlightTicketRescheduleApplication(data) {
  return request({
    url: `/api/v1/flightTicketRescheduleApplication/add`,
    method: "post",
    data,
  });
}
export function addFlightTicketRescheduleApplication2(data) {
  return request({
    url: `/api/v1/employee/flightTicketRescheduleApplication/add`,
    method: "post",
    data,
  });
}

//11-4 获取详情

export function getFlightTicketRescheduleApplicationItem(params) {
  return request({
    url: `/api/v1/flightTicketRescheduleApplications/${params.key}`,
    method: "get",
    params,
  });
}
export function getFlightTicketRescheduleApplicationItem2(params) {
  return request({
    url: `/api/v1/employee/flightTicketRescheduleApplications/${params.key}`,
    method: "get",
    params,
  });
}

//11-5 编辑
export function editFlightTicketRescheduleApplicationItem(data) {
  return request({
    url: `/api/v1/flightTicketRescheduleApplication/edit`,
    method: "post",
    data,
  });
}

export function editFlightTicketRescheduleApplicationItem2(data) {
  return request({
    url: `/api/v1/employee/flightTicketRescheduleApplication/edit`,
    method: "post",
    data,
  });
}

//11-6 删除
export function deleteFlightTicketRescheduleApplicationItem(params) {
  return request({
    url: `/api/v1/flightTicketRescheduleApplication/delete`,
    method: "delete",
    params,
  });
}
export function deleteFlightTicketRescheduleApplicationItem2(params) {
  return request({
    url: `/api/v1/employee/flightTicketRescheduleApplication/delete`,
    method: "delete",
    params,
  });
}
// 11-7 修改外事关系
export function putFlightTicketRescheduleApplicationItem(data) {
  return request({
    url: `/api/v1/flightTicketRescheduleApplication/put`,
    method: "post",
    data,
  });
}
/*
12、机票订购
*/
// 12-1 列表
export function getTicketBookings(params) {
  return request({
    url: "/api/v1/ticketBookings",
    method: "get",
    params,
  });
}

//12-2 编辑
export function editTicketBookingsItem(data) {
  return request({
    url: `/api/v1/ticketBooking/update`,
    method: "post",
    data,
  });
}

//12-3 获取详情
export function getTicketBookingsItem(params) {
  return request({
    url: `/api/v1/ticketBookings/${params.key}`,
    method: "get",
    params,
  });
}

//12-4 导入
export function importTicketBookings(data) {
  return request({
    url: `/api/v1/ticketBooking/import`,
    method: "post",
    data,
  });
}
//12-5 导出
export function exportTicketBookings(params) {
  return request({
    url: `/api/v1/ticketBooking/export`,
    method: "get",
    params,
  });
}

//12-6 导入模板
export function importTicketBookingsTemplate(params) {
  return request({
    url: `/api/v1/ticketBooking/import/template`,
    method: "get",
    params,
  });
}

//12-7 修改机票状态
export function updateTicketStatus(data) {
  return request({
    url: `/api/v1/ticketBooking/ticketStatus/update`,
    method: "post",
    data,
  });
}

/*
13、接送站
*/
// 13-1 列表
export function getDropOfforPickup(params) {
  return request({
    url: "/api/v1/dropOfforPickup",
    method: "get",
    params,
  });
}

/*
14、岗位类别
*/
// 14-1 列表
export function positionCategories(params) {
  return request({
    url: "/api/v1/positionCategories",
    method: "get",
    params,
  });
}

// 14-2 添加
export function addPositionCategory(data) {
  return request({
    url: "/api/v1/positionCategory/add",
    method: "post",
    data,
  });
}

// 14-3 编辑
export function updatePositionCategory(data) {
  return request({
    url: "/api/v1/positionCategory/update",
    method: "post",
    data,
  });
}

// 14-4 删除
export function deletePositionCategory(params) {
  return request({
    url: "/api/v1/positionCategory/delete",
    method: "delete",
    params,
  });
}

/*
15、当地岗位服务外包订单
*/
// 15-1 列表
export function getPurchaseOrderApplication(params) {
  return request({
    url: "/api/v1/purchaseOrderApplication",
    method: "get",
    params,
  });
}

// 15-2
export function getPurchaseOrderApplicationSuppliers(params) {
  return request({
    url: "/api/v1/purchaseOrderApplication/suppliers",
    method: "get",
    params,
  });
}

// 15-3 新增
export function addPurchaseOrderApplication(data) {
  return request({
    url: "/api/v1/purchaseOrderApplication/add",
    method: "post",
    data,
  });
}

// 15-4 详情
export function getPurchaseOrderApplicationItem(params) {
  return request({
    url: `/api/v1/purchaseOrderApplications/${params.key}`,
    method: "get",
    params,
  });
}

//15-5 修改
export function editPurchaseOrderApplication(data) {
  return request({
    url: "/api/v1/purchaseOrderApplication/edit",
    method: "post",
    data,
  });
}

//15-6 删除
export function deltePurchaseOrderApplication(params) {
  return request({
    url: "/api/v1/purchaseOrderApplication/delete",
    method: "delete",
    params,
  });
}

// 15-7 依赖数据
export function getPurchaseOrderApplicationOrders(params) {
  return request({
    url: "/api/v1/purchaseOrderApplication/orders",
    method: "get",
    params,
  });
}

/*
16、订单数据库
*/

// 16-1 列表
export function getPurchaseOrderAccountings(params) {
  return request({
    url: "/api/v1/purchaseOrderAccountings",
    method: "get",
    params,
  });
}

// 16-2 导出
export function exportPurchaseOrderAccountings(params) {
  return request({
    url: "/api/v1/purchaseOrderAccounting/export",
    method: "get",
    params,
  });
}

/*
17、当地岗位服务外包单位档案管理 
*/

// 17-1 列表
export function getManpowerSupplierList(params) {
  return request({
    url: "/api/v1/manpowerSupplierList",
    method: "get",
    params,
  });
}
// 17-2 新增
export function addManpowerSupplierListItem(data) {
  return request({
    url: "/api/v1/manpowerSupplierList/add",
    method: "post",
    data,
  });
}

//17-3 删除
export function deleteManpowerSupplierListItem(params) {
  return request({
    url: "/api/v1/manpowerSupplierList/delete",
    method: "delete",
    params,
  });
}
// 17-4 详情
export function getManpowerSupplierListItem(params) {
  return request({
    url: `/api/v1/manpowerSupplierList/${params.key}`,
    method: "get",
    params,
  });
}
//17-5 编辑
export function editManpowerSupplierListItem(data) {
  return request({
    url: `/api/v1/manpowerSupplierList/edit`,
    method: "post",
    data,
  });
}

//17-6 导入模板
export function getManpowerSupplierListTemplate(params) {
  return request({
    url: `/api/v1/manpowerSupplierList/import/template`,
    method: "get",
    params,
  });
}

//17-7 导入
export function importManpowerSupplierListTemplate(data) {
  return request({
    url: `/api/v1/manpowerSupplierList/import`,
    method: "post",
    data,
  });
}

//17-8 导出
export function exportManpowerSupplierListTemplate(params) {
  return request({
    url: `/api/v1/manpowerSupplierList/export`,
    method: "get",
    params,
  });
}

/*
18、续签管理
*/
// 18-1 列表
export function getManpowerSupplierAssessments(params) {
  return request({
    url: "/api/v1/manpowerSupplierAssessments",
    method: "get",
    params,
  });
}

// 18-2 添加
export function addManpowerSupplierAssessmentsItem(data) {
  return request({
    url: "/api/v1/manpowerSupplierAssessment/add",
    method: "post",
    data,
  });
}

// 18-3 详情
export function getManpowerSupplierAssessmentsItem(params) {
  return request({
    url: `/api/v1/manpowerSupplierAssessments/${params.key}`,
    method: "get",
    params,
  });
}

// 18-4 编辑
export function editManpowerSupplierAssessmentsItem(data) {
  return request({
    url: `/api/v1/manpowerSupplierAssessment/edit`,
    method: "post",
    data,
  });
}
// 18-5 删除
export function deleteManpowerSupplierAssessmentsItem(params) {
  return request({
    url: `/api/v1/manpowerSupplierAssessment/delete`,
    method: "delete",
    params,
  });
}

// 18-6 导入
export function importManpowerSupplierAssessment(data) {
  return request({
    url: `/api/v1/manpowerSupplierAssessment/import`,
    method: "post",
    data,
  });
}

// 18-7 导入模板
export function getManpowerSupplierAssessmentTemplate(params) {
  return request({
    url: `/api/v1/manpowerSupplierAssessment/import/template`,
    method: "get",
    params,
  });
}

/*
19、奖惩管理
*/
// 19-1 列表
export function getManpowerSupplierPunishmentAndRewards(params) {
  return request({
    url: "/api/v1/manpowerSupplierPunishmentAndRewards",
    method: "get",
    params,
  });
}

// 19-2 添加
export function addManpowerSupplierPunishmentAndReward(data) {
  return request({
    url: "/api/v1/manpowerSupplierPunishmentAndReward/add",
    method: "post",
    data,
  });
}

// 19-3 详情
export function getManpowerSupplierPunishmentAndRewardsItem(params) {
  return request({
    url: `/api/v1/manpowerSupplierPunishmentAndRewards/${params.key}`,
    method: "get",
    params,
  });
}

//19-4 修改
export function editManpowerSupplierPunishmentAndReward(data) {
  return request({
    url: "/api/v1/manpowerSupplierPunishmentAndReward/edit",
    method: "post",
    data,
  });
}

// 19-5 删除

export function deleteManpowerSupplierPunishmentAndRewardItem(params) {
  return request({
    url: `/api/v1/manpowerSupplierPunishmentAndReward/delete`,
    method: "delete",
    params,
  });
}

// 19-6 导入
export function importmanpowerSupplierPunishmentAndReward(data) {
  return request({
    url: `/api/v1/manpowerSupplierPunishmentAndReward/import`,
    method: "post",
    data,
  });
}

// 19-7 导入模板
export function getManpowerSupplierPunishmentAndRewardTemplate(params) {
  return request({
    url: `/api/v1/manpowerSupplierPunishmentAndReward/import/template`,
    method: "get",
    params,
  });
}

/*
20、机票费用
*/

// 20-1 列表
export function getTicketFares(params) {
  return request({
    url: "/api/v1/ticketFares",
    method: "get",
    params,
  });
}

//20-2 编辑
export function editTicketFareItem(data) {
  return request({
    url: `/api/v1/ticketFare/update`,
    method: "post",
    data,
  });
}

//20-3 获取详情
export function getTicketFaresItem(params) {
  return request({
    url: `/api/v1/ticketFares/${params.key}`,
    method: "get",
    params,
  });
}

//20-4 导入
export function importTicketFare(data) {
  return request({
    url: `/api/v1/ticketFare/import`,
    method: "post",
    data,
  });
}
//20-5 导出
export function exportTicketFare(params) {
  return request({
    url: `/api/v1/ticketFare/export`,
    method: "get",
    params,
  });
}

//20-6 导入模板
export function importTicketFareTemplate(params) {
  return request({
    url: `/api/v1/ticketFare/import/template`,
    method: "get",
    params,
  });
}

/*
21、当地岗位服务外包费明细
*/
// 21-1 列表
export function getManpowerSupplierMonthlyCostAccountings(params) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostAccountings",
    method: "get",
    params,
  });
}

// 21-2 导出
export function exportManpowerSupplierMonthlyCostAccountings(params) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostAccounting/export",
    method: "get",
    params,
  });
}

/*
22、飞行路线
*/
// 22-1 列表
export function getAirlines(params) {
  return request({
    url: "/api/v1/airlines",
    method: "get",
    params,
  });
}
// 22-2 新增
export function addAirlines(data) {
  return request({
    url: "/api/v1/airline/add",
    method: "post",
    data,
  });
}
// 22-3 详情
export function getAirlinesItem(params) {
  return request({
    url: `/api/v1/airlines/${params.id}`,
    method: "get",
    params,
  });
}
// 22-4 修改
export function updateAirlines(data) {
  return request({
    url: "/api/v1/airline/update",
    method: "post",
    data,
  });
}
// 22-5 删除
export function deleteAirlines(data) {
  return request({
    url: "/api/v1/airline/delete",
    method: "delete",
    data,
  });
}

/*
23、月度挂账管理
*/
// 23-1 列表
export function getManpowerSupplierMonthlyCostApplicationDetailAccountings(
  params
) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostApplicationDetailAccountings",
    method: "get",
    params,
  });
}

// 23-2 导出
export function exportManpowerSupplierMonthlyCostApplicationDetailAccountings(
  params
) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostApplicationDetailAccounting/export",
    method: "get",
    params,
  });
}

/*
24、当地岗位服务费月度挂账
*/

// 24-1 列表
export function getManpowerSupplierMonthlyCostApplicationDetails(params) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostApplicationDetails",
    method: "get",
    params,
  });
}

//24-2 导入模板
export function importManpowerSupplierMonthlyCostDetailTemplate(params) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostDetail/import/template",
    method: "get",
    params,
  });
}

//24-3 新增
export function addManpowerSupplierMonthlyCostApplicationDetail(data) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostApplicationDetail/add",
    method: "post",
    data,
  });
}

// 24-4 编辑
export function editManpowerSupplierMonthlyCostApplicationDetail(data) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostApplicationDetail/edit",
    method: "post",
    data,
  });
}

// 24-5 删除
export function deleteManpowerSupplierMonthlyCostApplicationDetail(params) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostApplicationDetail/delete",
    method: "delete",
    params,
  });
}

// 24-6 添加审批表
export function addManpowerSupplierMonthlyCostApplication(data) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostApplication/add",
    method: "post",
    data,
  });
}

// 24-7 明细列表
export function getManpowerSupplierMonthlyCostDetails(params) {
  return request({
    url: "/api/v1/manpowerSupplierMonthlyCostDetails",
    method: "get",
    params,
  });
}

// 24-8 详情
export function getManpowerSupplierMonthlyCostApplicationDetailsItem(params) {
  return request({
    url: `/api/v1/manpowerSupplierMonthlyCostApplicationDetails/${params.key}`,
    method: "get",
    params,
  });
}

// 24-9 修改付款方式
export function putManpowerSupplierMonthlyCostApplicationDetail(data) {
  return request({
    url: `/api/v1/manpowerSupplierMonthlyCostApplicationDetail/put`,
    method: "post",
    data,
  });
}

/*
25、内部借工协议
*/
// 25-1 列表

export function getInternalTemporaryManpowerApplications(params) {
  return request({
    url: "/api/v1/internalTemporaryManpowerApplications",
    method: "get",
    params,
  });
}

// 25-2 添加
export function addInternalTemporaryManpowerApplication(data) {
  return request({
    url: "/api/v1/internalTemporaryManpowerApplication/add",
    method: "post",
    data,
  });
}

//25-3 详情
export function getInternalTemporaryManpowerApplicationsItem(params) {
  return request({
    url: `/api/v1/internalTemporaryManpowerApplications/${params.key}`,
    method: "get",
    params,
  });
}

//25-4 编辑
export function editInternalTemporaryManpowerApplicationsItem(data) {
  return request({
    url: `/api/v1/internalTemporaryManpowerApplication/edit`,
    method: "post",
    data,
  });
}

// 25-5 删除
export function deleteInternalTemporaryManpowerApplicationsItem(params) {
  return request({
    url: `/api/v1/internalTemporaryManpowerApplication/delete`,
    method: "delete",
    params,
  });
}

/*
26、出国(境)任务
*/

// 26-1 列表
export function getOverseasWorksApplications(params) {
  return request({
    url: "/api/v1/overseasWorksApplications",
    method: "get",
    params,
  });
}

// 26-2 新增
export function addOverseasWorksApplication(data) {
  return request({
    url: "/api/v1/overseasWorksApplication/add",
    method: "post",
    data,
  });
}

// 26-3 详情
export function getOverseasWorksApplicationsItem(params) {
  return request({
    url: `/api/v1/overseasWorksApplications/${params.key}`,
    method: "get",
    params,
  });
}

// 26-4 编辑
export function editOverseasWorksApplicationsItem(data) {
  return request({
    url: `/api/v1/overseasWorksApplication/edit`,
    method: "post",
    data,
  });
}

// 26-5 删除
export function deleteOverseasWorksApplicationsItem(params) {
  return request({
    url: `/api/v1/overseasWorksApplication/delete`,
    method: "delete",
    params,
  });
}
/*
27、境外公共安全培训
*/

// 27-1 列表
export function getRegistrationforPublicSafetyTrainingApplications(params) {
  return request({
    url: "/api/v1/registrationforPublicSafetyTrainingApplications2",
    method: "get",
    params,
  });
}

// 27-2 新增
export function addRegistrationforPublicSafetyTrainingApplication(data) {
  return request({
    url: "/api/v1/registrationforPublicSafetyTrainingApplication/add",
    method: "post",
    data,
  });
}

// 27-3 详情
export function getRegistrationforPublicSafetyTrainingApplicationsItem(params) {
  return request({
    url: `/api/v1/registrationforPublicSafetyTrainingApplications/${params.key}`,
    method: "get",
    params,
  });
}

// 27-4 编辑
export function editRegistrationforPublicSafetyTrainingApplicationItem(data) {
  return request({
    url: `/api/v1/registrationforPublicSafetyTrainingApplication/edit`,
    method: "post",
    data,
  });
}

// 27-5 删除
export function deleteRegistrationforPublicSafetyTrainingApplicationItem(
  params
) {
  return request({
    url: `/api/v1/registrationforPublicSafetyTrainingApplication/delete`,
    method: "delete",
    params,
  });
}

// 27-6 获取最近一次培训记录
export function getTrainings(params) {
  return request({
    url: `/api/v1/registrationforPublicSafetyTrainingApplication/trainings`,
    method: "get",
    params,
  });
}

/*
28、国家地区管理
*/

// 28-1 列表
export function getCountries(params) {
  return request({
    url: "/api/v1/countries",
    method: "get",
    params,
  });
}

// 28-2 新增
export function addCountries(data) {
  return request({
    url: "/api/v1/country/add",
    method: "post",
    data,
  });
}

// 28-3 修改
export function updateCountries(data) {
  return request({
    url: "/api/v1/country/update",
    method: "post",
    data,
  });
}

//28-4 删除
export function deleteCountries(data) {
  return request({
    url: "/api/v1/country/delete",
    method: "delete",
    data,
  });
}

//28-5 详情
export function getCountriesItem(params) {
  return request({
    url: `/api/v1/countries/${params.id}`,
    method: "get",
    params,
  });
}

//28-6 获取全部城市
export function allCountry(params) {
  return request({
    url: `/api/v1/country/all`,
    method: "get",
    params,
  });
}

/*
29、城市管理
*/
// 29-1 列表
export function getCities(params) {
  return request({
    url: "/api/v1/cities",
    method: "get",
    params,
  });
}

// 29-2 新增
export function addCities(data) {
  return request({
    url: "/api/v1/city/add",
    method: "post",
    data,
  });
}

// 29-3 修改
export function updateCities(data) {
  return request({
    url: "/api/v1/city/update",
    method: "post",
    data,
  });
}

//29-4 删除
export function deleteCities(data) {
  return request({
    url: "/api/v1/city/delete",
    method: "delete",
    data,
  });
}

//29-5 详情
export function getCitiesItem(params) {
  return request({
    url: `/api/v1/cities/${params.id}`,
    method: "get",
    params,
  });
}

//29-6 获取全部城市
export function allCities(params) {
  return request({
    url: `/api/v1/city/all`,
    method: "get",
    params,
  });
}

/*
30、境外公共安全培训台账
*/

// 30-1 列表
export function getRegistrationforPublicSafetyTrainingAccountings(params) {
  return request({
    url: `/api/v1/registrationforPublicSafetyTrainingAccountings`,
    method: "get",
    params,
  });
}

//30-2 导出
export function exportRegistrationforPublicSafetyTrainingAccounting(params) {
  return request({
    url: `/api/v1/registrationforPublicSafetyTrainingAccounting/export`,
    method: "get",
    params,
  });
}

/*
31、SPONSOR转入/转出
*/
// 31-1 列表
export function getTransferSponsorApplications2(params) {
  return request({
    url: `/api/v1/transferSponsorApplications2`,
    method: "get",
    params,
  });
}

// 31-2 新增
export function addTransferSponsorApplication(data) {
  return request({
    url: `/api/v1/transferSponsorApplication/add`,
    method: "post",
    data,
  });
}

//31-3 详情
export function getTransferSponsorApplicationsItem(params) {
  return request({
    url: `/api/v1/transferSponsorApplications/${params.key}`,
    method: "get",
    params,
  });
}

//31-4 编辑
export function editTransferSponsorApplications(data) {
  return request({
    url: `/api/v1/transferSponsorApplication/edit`,
    method: "post",
    data,
  });
}

//31-5 删除
export function deleteTransferSponsorApplications(params) {
  return request({
    url: `/api/v1/transferSponsorApplication/delete`,
    method: "delete",
    params,
  });
}

/*
32、第三国签证申请
*/
// 32-1 列表
export function getVistVisaofNonWorkingCountryApplications2(params) {
  return request({
    url: `/api/v1/vistVisaofNonWorkingCountryApplications2`,
    method: "get",
    params,
  });
}

// 32-2 新增
export function addVistVisaofNonWorkingCountryApplication(data) {
  return request({
    url: `/api/v1/vistVisaofNonWorkingCountryApplication/add`,
    method: "post",
    data,
  });
}

//32-3 详情
export function getVistVisaofNonWorkingCountryApplicationsItem(params) {
  return request({
    url: `/api/v1/vistVisaofNonWorkingCountryApplications/${params.key}`,
    method: "get",
    params,
  });
}

//32-4 编辑
export function editVistVisaofNonWorkingCountryApplication(data) {
  return request({
    url: `/api/v1/vistVisaofNonWorkingCountryApplication/edit`,
    method: "post",
    data,
  });
}

//32-5 删除
export function deleteVistVisaofNonWorkingCountryApplication(params) {
  return request({
    url: `/api/v1/vistVisaofNonWorkingCountryApplication/delete`,
    method: "delete",
    params,
  });
}

/*
33、签证状态跟踪(赴沙)管理
*/

// 33-1 列表
export function getRecordforVisas(params) {
  return request({
    url: `/api/v1/recordforVisas`,
    method: "get",
    params,
  });
}

// 33-2 导出
export function exportRecordforVisas(params) {
  return request({
    url: `/api/v1/recordforVisa/export`,
    method: "get",
    params,
  });
}

//33-3 详情
export function getRecordforVisasItem(params) {
  return request({
    url: `/api/v1/recordforVisas/${params.key}`,
    method: "get",
    params,
  });
}

// 33-4 更新
export function updateRecordforVisa(data) {
  return request({
    url: `/api/v1/recordforVisa/update`,
    method: "post",
    data,
  });
}

// 33-4 更新1
export function updateRecordforVisa1(data) {
  return request({
    url: `/api/v1/recordforVisa/documents/update`,
    method: "post",
    data,
  });
}

// 33-5 更新2
export function updateRecordforVisa2(data) {
  return request({
    url: `/api/v1/recordforVisa/visaInfo/update`,
    method: "post",
    data,
  });
}
// 33-6 更新3
export function updateRecordforVisa3(data) {
  return request({
    url: `/api/v1/recordforVisa/remark/update`,
    method: "post",
    data,
  });
}

/*
34、签证状态跟踪(离沙)管理 
*/

// 34-1 列表
export function getVisaRecordofBusinesses(params) {
  return request({
    url: `/api/v1/visaRecordofBusinesses`,
    method: "get",
    params,
  });
}

// 34-2 导出
export function exportVisaRecordofBusinesses(params) {
  return request({
    url: `/api/v1/visaRecordofBusinesses/export`,
    method: "get",
    params,
  });
}

//34-3 详情
export function getVisaRecordofBusinessesItem(params) {
  return request({
    url: `/api/v1/visaRecordofBusinesses/${params.key}`,
    method: "get",
    params,
  });
}

// 34-4 更新1
export function updateVisaRecordofBusiness(data) {
  return request({
    url: `/api/v1/visaRecordofBusiness/update`,
    method: "post",
    data,
  });
}

// 34-4 更新1
export function updateVisaRecordofBusiness1(data) {
  return request({
    url: `/api/v1/visaRecordofBusiness/foreignAffairsStatus/update`,
    method: "post",
    data,
  });
}

// 34-5 更新2
export function updateVisaRecordofBusiness2(data) {
  return request({
    url: `/api/v1/visaRecordofBusiness/businessTripApplicationNumber/update`,
    method: "post",
    data,
  });
}
//34-6 更新3
export function updateVisaRecordofBusiness3(data) {
  return request({
    url: `/api/v1/visaRecordofBusiness/overseasMission/update`,
    method: "post",
    data,
  });
}
//34-7 更新4
export function updateVisaRecordofBusiness4(data) {
  return request({
    url: `/api/v1/visaRecordofBusiness/documents/update`,
    method: "post",
    data,
  });
}
//34-8 更新5
export function updateVisaRecordofBusiness5(data) {
  return request({
    url: `/api/v1/visaRecordofBusiness/visaInfo/update`,
    method: "post",
    data,
  });
}
//34-9 更新6
export function updateVisaRecordofBusiness6(data) {
  return request({
    url: `/api/v1/visaRecordofBusiness/remark/update`,
    method: "post",
    data,
  });
}
//34-10 更新7
export function updateVisaRecordofBusiness7(data) {
  return request({
    url: `/api/v1/visaRecordofBusiness/usageVisaInfo/update`,
    method: "post",
    data,
  });
}

/*
35、签证预警管理
*/
// 35-1 列表
export function getVisaWarnings(params) {
  return request({
    url: `/api/v1/visaWarnings`,
    method: "get",
    params,
  });
}

/*
36、系统优化意见建议
 */

// 36-1 列表
export function getFeedbacks(params) {
  return request({
    url: `/api/v1/feedbacks`,
    method: "get",
    params,
  });
}

export function getFeedbacks2(params) {
  return request({
    url: `/api/v1/employee/feedbacks`,
    method: "get",
    params,
  });
}

// 36-2 新建
export function addFeedback(data) {
  return request({
    url: `/api/v1/feedback/add`,
    method: "post",
    data,
  });
}
export function addFeedback2(data) {
  return request({
    url: `/api/v1/employee/feedback/add`,
    method: "post",
    data,
  });
}
// 36-3 详情
export function getFeedbacksItem(params) {
  return request({
    url: `/api/v1/feedbacks/${params.id}`,
    method: "get",
    params,
  });
}
export function getFeedbacksItem2(params) {
  return request({
    url: `/api/v1/employee/feedbacks/${params.id}`,
    method: "get",
    params,
  });
}
// 36-4 编辑
export function updateFeedback(data) {
  return request({
    url: `/api/v1/feedback/update`,
    method: "post",
    data,
  });
}
export function updateFeedback2(data) {
  return request({
    url: `/api/v1/employee/feedback/update`,
    method: "post",
    data,
  });
}
// 36-5 删除
export function deleteFeedback(data) {
  return request({
    url: `/api/v1/feedback/delete`,
    method: "delete",
    data,
  });
}
export function deleteFeedback2(data) {
  return request({
    url: `/api/v1/employee/feedback/delete`,
    method: "delete",
    data,
  });
}
/*
 37、外国人访华业务审批
*/
// 37-1 列表
export function getInvitationLetterApplications22(params) {
  return request({
    url: "/api/v1/invitationLetterApplication2s",
    method: "get",
    params,
  });
}

// 37-2 新增
export function addInvitationLetterApplication22(data) {
  return request({
    url: "/api/v1/invitationLetterApplication2/add",
    method: "post",
    data,
  });
}

// 37-3 详情
export function getInvitationLetterApplicationItem22(params) {
  return request({
    url: `/api/v1/invitationLetterApplication2s/${params.key}`,
    method: "get",
    params,
  });
}

//37-4修改
export function updateInvitationLetterApplication22(data) {
  return request({
    url: "/api/v1/invitationLetterApplication2/edit",
    method: "post",
    data,
  });
}
// 37-5 删除
export function deleteInvitationLetterApplicationItem22(params) {
  return request({
    url: `/api/v1/invitationLetterApplication2/delete`,
    method: "delete",
    params,
  });
}

/*
 38、员工医保等级管理
*/

// 38-1 列表
export function getEmployeeMedicalInsuranceClassLevels(params) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceClassLevels",
    method: "get",
    params,
  });
}

// 38-2 新增
export function addEmployeeMedicalInsuranceClassLevel(data) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceClassLevel/add",
    method: "post",
    data,
  });
}

// 38-3 编辑
export function updateEmployeeMedicalInsuranceClassLevel(data) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceClassLevel/update",
    method: "post",
    data,
  });
}

// 38-4 详情
export function getEmployeeMedicalInsuranceClassLevelsItem(params) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceClassLevels/${params.id}`,
    method: "get",
    params,
  });
}

// 38-5 删除
export function deleteEmployeeMedicalInsuranceClassLevelsItem(data) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceClassLevel/delete`,
    method: "delete",
    data,
  });
}

// 38-6 获取全部
export function getEmployeeMedicalInsuranceClassLevelsAll(params) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceClassLevel/all`,
    method: "get",
    params,
  });
}
/*
 39、员工医疗保险注册
*/

// 39-1 列表
export function getEmployeeMedicalInsuranceRegistrationApplications2(params) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceRegistrationApplications2",
    method: "get",
    params,
  });
}

// 39-2 新增
export function addEmployeeMedicalInsuranceRegistrationApplication(data) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceRegistrationApplication/add",
    method: "post",
    data,
  });
}

// 39-3 编辑
export function updateEmployeeMedicalInsuranceRegistrationApplication(data) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceRegistrationApplication/edit",
    method: "post",
    data,
  });
}

// 39-4 详情
export function getEmployeeMedicalInsuranceRegistrationApplicationsItem(
  params
) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceRegistrationApplications/${params.key}`,
    method: "get",
    params,
  });
}

// 39-5 删除
export function deletEemployeeMedicalInsuranceRegistrationApplicationItem(
  params
) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceRegistrationApplication/delete`,
    method: "delete",
    params,
  });
}

/*
 40、员工医保变更
*/

// 40-1 列表
export function getEmployeeMedicalInsuranceAdjustmentmentApplications(params) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceAdjustmentmentApplications",
    method: "get",
    params,
  });
}

// 40-2 新增
export function addEmployeeMedicalInsuranceAdjustmentmentApplication(data) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceAdjustmentmentApplication/add",
    method: "post",
    data,
  });
}

// 40-3 编辑
export function updatEemployeeMedicalInsuranceAdjustmentmentApplication(data) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceAdjustmentmentApplication/edit",
    method: "post",
    data,
  });
}

// 40-4 详情
export function getEmployeeMedicalInsuranceAdjustmentmentApplicationsItem(
  params
) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceAdjustmentmentApplications/${params.key}`,
    method: "get",
    params,
  });
}

// 40-5 删除
export function deleteEployeeMedicalInsuranceAdjustmentmentApplicationItem(
  params
) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceAdjustmentmentApplication/delete`,
    method: "delete",
    params,
  });
}

/*
41、医保购买或台账
*/
// 41-1 列表
export function getEmployeeMedicalInsuranceRecords(params) {
  return request({
    url: "/api/v1/employeeMedicalInsuranceRecords",
    method: "get",
    params,
  });
}

// 41-2 获取列表子项目
export function getEmployeeMedicalInsuranceRecordsChildren(params) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceRecords/${params.key}/children`,
    method: "get",
    params,
  });
}

// 41-3 删除
export function deleteEmployeeMedicalInsuranceRecordsChildren(params) {
  return request({
    url: `/api/v1/EmployeeMedicalInsuranceRecords/delete`,
    method: "delete",
    params,
  });
}

// 41-4 导入模板
export function getEmployeeMedicalInsuranceRecordsTemplate(params) {
  return request({
    url: `/api/v1/EmployeeMedicalInsuranceRecords/import/template`,
    method: "get",
    params,
  });
}
// 41-5 导入
export function importEmployeeMedicalInsuranceRecords(data) {
  return request({
    url: `/api/v1/EmployeeMedicalInsuranceRecords/import`,
    method: "post",
    data,
  });
}

// 41-6 导出
export function exportEmployeeMedicalInsuranceRecords(params) {
  return request({
    url: `/api/v1/EmployeeMedicalInsuranceRecords/export`,
    method: "get",
    params,
  });
}

export function getEmployeeMedicalInsuranceRecordsItem(params) {
  return request({
    url: `/api/v1/employeeMedicalInsuranceRecords/${params.key}`,
    method: "get",
    params,
  });
}

export function editEmployeeMedicalInsuranceRecords(data) {
  return request({
    url: `/api/v1/EmployeeMedicalInsuranceRecords/edit`,
    method: "post",
    data,
  });
}

/*
42、因公临时出国(境)出行情况
*/

// 42-1 列表
export function getOverseasWorksAccountings(params) {
  return request({
    url: "/api/v1/overseasWorksAccountings",
    method: "get",
    params,
  });
}

// 42-2 修改
export function updateOverseasWorksAccounting(data) {
  return request({
    url: "/api/v1/overseasWorksAccounting/dateAndDays/update",
    method: "post",
    data,
  });
}

/*
43、签证指标相关管理
*/

// 43-1 列表
export function getWorkVisaApplications2(params) {
  return request({
    url: "/api/v1/workVisaApplications2",
    method: "get",
    params,
  });
}

// 43-2 添加
export function addWorkVisaApplication(data) {
  return request({
    url: "/api/v1/workVisaApplication/add",
    method: "post",
    data,
  });
}

// 43-3 详情
export function getWorkVisaApplicationsItem(params) {
  return request({
    url: `/api/v1/workVisaApplications/${params.key}`,
    method: "get",
    params,
  });
}

// 43-4 更新
export function editWorkVisaApplication(data) {
  return request({
    url: "/api/v1/workVisaApplication/edit",
    method: "post",
    data,
  });
}

// 43-5 删除
export function deleteWorkVisaApplication(params) {
  return request({
    url: "/api/v1/workVisaApplication/delete",
    method: "delete",
    params,
  });
}

/*
44、员工岗位变动
*/

// 44-1、列表
export function getPositionAdjustmentApplicantions(params) {
  return request({
    url: "/api/v1/positionAdjustmentApplicantions",
    method: "get",
    params,
  });
}

export function getPositionAdjustmentApplicantions2(params) {
  return request({
    url: "/api/v1/employee/positionAdjustmentApplicantions",
    method: "get",
    params,
  });
}

// 44-2、详情
export function getPositionAdjustmentApplicantionsItem(params) {
  return request({
    url: `/api/v1/positionAdjustmentApplicantions/${params.key}`,
    method: "get",
    params,
  });
}
export function getPositionAdjustmentApplicantionsItem2(params) {
  return request({
    url: `/api/v1/employee/positionAdjustmentApplicantions/{${params.key}`,
    method: "get",
    params,
  });
}
// 44-3、删除
export function deletePositionAdjustmentApplicantionsItem(params) {
  return request({
    url: `/api/v1/positionAdjustmentApplicantion/delete`,
    method: "delete",
    params,
  });
}
export function deletePositionAdjustmentApplicantionsItem2(params) {
  return request({
    url: `/api/v1/employee/positionAdjustmentApplicantion/delete`,
    method: "delete",
    params,
  });
}
// 44-4、添加
export function addPositionAdjustmentApplicantions(data) {
  return request({
    url: `/api/v1/positionAdjustmentApplicantion/add`,
    method: "post",
    data,
  });
}
export function addPositionAdjustmentApplicantions2(data) {
  return request({
    url: `/api/v1/employee/positionAdjustmentApplicantion/add`,
    method: "post",
    data,
  });
}
// 44-5、编辑
export function editPositionAdjustmentApplicantions(data) {
  return request({
    url: `/api/v1/positionAdjustmentApplicantion/edit`,
    method: "post",
    data,
  });
}
export function editPositionAdjustmentApplicantions2(data) {
  return request({
    url: `/api/v1/employee/positionAdjustmentApplicantion/edit`,
    method: "post",
    data,
  });
}
// 获取所有用工形式
export function getEmploymentFormAll(params) {
  return request({
    url: `/api/v1/employmentForm/all`,
    method: "get",
    params,
  });
}

// 获取所有岗位等级
export function getPositionLevelAll(params) {
  return request({
    url: `/api/v1/positionLevel/all`,
    method: "get",
    params,
  });
}

/*
45、员工岗位变动批量调整管理 
 */
// 45-1 列表
export function getPositionAdjustmentApplicantionOfflines(params) {
  return request({
    url: `/api/v1/positionAdjustmentApplicantionOfflines`,
    method: "get",
    params,
  });
}

//45-2 导入模板
export function getPositionAdjustmentApplicantionOfflinesTemplate(params) {
  return request({
    url: `/api/v1/positionAdjustmentApplicantionOffline/import/template`,
    method: "get",
    params,
  });
}

//45-3 导入
export function importPositionAdjustmentApplicantion(data) {
  return request({
    url: `/api/v1/positionAdjustmentApplicantion/import`,
    method: "post",
    data,
  });
}

//45-4 删除
export function deletePositionAdjustmentApplicantionOffline(params) {
  return request({
    url: `/api/v1/positionAdjustmentApplicantionOffline/delete`,
    method: "delete",
    params,
  });
}
